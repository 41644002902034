<template>
  <div v-if="blogs.length">
    <h2>{{ t('title') }}</h2>
    <template v-if="isDesktop">
      <LazyZGrid>
        <LazyZGridItem
          v-for="(blog, index) in blogs"
          :key="index"
          md="6"
          lg="3"
        >
          <LazyZCard no-body>
            <a
              :href="blog.url"
              target="_blank"
              @click="trackEvent(blog)"
            ><LazyZImage
              :src="blog.image"
              :alt="blog.title"
              height="194"
              width="100%"
              preserve-aspect
            /></a>
            <LazyZCardBody>
              <a
                :href="blog.url"
                target="_blank"
                @click="trackEvent(blog)"
              >
                <h3>
                  {{ blog.title }}
                </h3>
              </a>
            </LazyZCardBody>
          </LazyZCard>
        </LazyZGridItem>
      </LazyZGrid>
    </template>

    <template v-else>
      <LazyGlide
        ref="glideEl"
        v-element-visibility="mountOnVisible"
        :slides="blogs"
      >
        <template #slide="{ slide: blog }">
          <a
            :href="blog.url"
            target="_blank"
            @click="trackEvent(blog)"
          >
            <LazyZCard no-body>
              <LazyZImage
                :src="blog.image"
                :alt="blog.title"
                height="194"
                width="100%"
                preserve-aspect
              />
              <LazyZCardBody>
                <h3>
                  {{ blog.title }}
                </h3>
              </LazyZCardBody>
            </LazyZCard>
          </a>
        </template>
      </LazyGlide>
    </template>
  </div>
</template>

<script setup lang="ts">
import { vElementVisibility } from '@vueuse/components'
import type { ComponentInstance } from 'vue'
import type { Glide as ZGlide } from '#components'

const { isDesktop } = useDevice()
const localePath = useLocalePath()
const { t, locale } = useI18n()
const { routeBaseName } = useBaseName()
const { trackTipsAndResourcesCardClicked } = useTracking()
const { user } = useAuthentication()

const glideEl = ref<ComponentInstance<typeof ZGlide> | null>(null)
let glide: Glide | null = null

const mountOnVisible = async () => {
  const { default: Glide, Swipe, Controls } = await import('@glidejs/glide/dist/glide.modular.esm')

  if (glide) {
    return
  }

  glide = new Glide(glideEl.value?.$el, {
    type: 'carousel',
    perView: 1.3,
  })

  glide.mount({ Swipe, Controls })
}

const trackEvent = (blog: { title?: string, image?: string, url?: string }) => {
  trackTipsAndResourcesCardClicked({
    accountCountry: user.value?.CountryString?.toLowerCase() as 'au' | 'ca' | 'de' | 'fr' | 'gb' | 'it' | 'nz' | 'us',
    userId: user.value?.Id,
    tipsAndResourcesCardName: blog.title || '',
    pageSource: routeBaseName.value,
  })
}

// Load the blog posts
const tag = locale.value === 'fr' ? 'hash-host-tips-featured-fr' : 'hash-host-tips-featured'

const { getPostsByTag } = useGhostApi()
const { data } = await getPostsByTag(tag)

const blogs = computed(
  () =>
    data.value?.posts?.slice(0, 4).map((post) => {
      const url = `${localePath({
        name: 'blog',
      })}/${post.slug}`
      return { title: post.title, image: post.feature_image, url }
    }) || [],
)

onBeforeUnmount(() => {
  glide?.destroy()
})
</script>

<style lang="scss" scoped>
.zgrid {
  gap: 2rem;
}

h2 {
  color: getColor('primary-500');
}

h3 {
  @include subtitle-1;
  display: -webkit-box;
  color: getColor('primary-500');
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.zcard {
  border-radius: 0.5rem;
  overflow: hidden;
}
</style>

<i18n lang="json">
{
  "en": {
    "title": "Tips and resources"
  },
  "fr": {
    "title": "Conseils et ressources"
  }
}
</i18n>
